<template>
  <tasks-row-chart
    v-if="dhmeProjectAssemblyTasks.length > 0"
    :tasks="dhmeProjectAssemblyTasks"
    :headers="sortedLocations"
    :get-header-tasks="getTasksByLocation"
    :vacation-days="dhmeProjectAssemblyPlanningCalendar"
    :highlighted-items="highlightedTasksIds"
  >
    <template #task-header-items>
      <div
        v-for="location in sortedLocations"
        :key="location.id"
        class="d-flex align-center px-2 py-1"
        style="height: 36px; border-bottom: solid 1px gainsboro"
      >
        <div
          class="lane-color-block mr-2"
          :style="{ 'background-color': getLaneColor(location) }"
        />
        {{ location.assembly_location }}
        <v-spacer></v-spacer>
      </div>
    </template>
  </tasks-row-chart>
  <div v-else class="d-flex align-center justify-center flex-1">
    Generate assembly planning in the top right
  </div>
</template>

<script>
import TasksRowChart from '@/components/Charts/TasksRowChart.vue';
import { mapGetters } from 'vuex';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';

export default {
  name: 'DhmeProjectAssemblyPlanningManual',
  components: { TasksRowChart },
  props: {
    highlightedModuleType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'dhmeProjectAssemblyHallLocations',
      'dhmeProjectAssemblyHallLanes',
      'dhmeProjectAssemblyTasks',
      'dhmeProjectAssemblyPlanningCalendar',
      'dhmeProjectAssemblyPlanningModules',
    ]),

    highlightedTasksIds() {
      return this.dhmeProjectAssemblyTasks
        .filter((t) =>
          this.dhmeProjectAssemblyPlanningModules.some(
            (m) =>
              m.module_id === t?.sbscode?.code &&
              m.module_type === this.highlightedModuleType
          )
        )
        .map((t) => t.id);
    },

    sortedLocations() {
      const lanesMap = this.dhmeProjectAssemblyHallLanes.reduce((acc, lane) => {
        acc[lane.id] = lane.order;
        return acc;
      }, {});

      return [...this.dhmeProjectAssemblyHallLocations].sort((a, b) => {
        const laneOrderA = lanesMap[a.assembly_lane] || 0;
        const laneOrderB = lanesMap[b.assembly_lane] || 0;

        if (laneOrderA !== laneOrderB) {
          return laneOrderA - laneOrderB;
        }

        return (a.order || 0) - (b.order || 0);
      });
    },
  },
  methods: {
    getTasksByLocation(header) {
      return this.dhmeProjectAssemblyTasks.filter(
        (task) => task.task_type.custom_3 === header.id
      );
    },
    getLaneColor(location) {
      let lane = this.dhmeProjectAssemblyHallLocations.find(
        (item) => item.assembly_lane === location.assembly_lane
      );
      return stringToConstantColor(`${lane.assembly_location}${lane.id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.lane-color-block {
  width: 5px;
  height: 100%;
  border-radius: 15px;
}
</style>
